import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import YoutubeVideo from "components/Internet_Access/Point_to_Point/YoutubeVideoCardRemote";
import NavButtons from 'components/Internet_Access/NavButtons';
import ForumBox from 'components/Internet_Access/Point_to_Point/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "The P2P Remote Access",
  "path": "/Internet_Access/Point_to_Point/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Setup a Point2Point connection with your camera",
  "image": "./IA_SearchThumb_P2P.png",
  "social": "/images/Search/IA_SearchThumb_P2P.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-P2P_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='The P2P Remote Access' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Setup a Point2Point connection with your camera' image='/images/Search/IA_SearchThumb_P2P.png' twitter='/images/Search/IA_SearchThumb_P2P.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Point_to_Point/' locationFR='/fr/Internet_Access/Point_to_Point/' crumbLabel="INSTAR P2P" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-p2p",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-p2p",
        "aria-label": "instar p2p permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR P2P`}</h1>
    <h2 {...{
      "id": "setup-a-point2point-connection-with-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setup-a-point2point-connection-with-your-camera",
        "aria-label": "setup a point2point connection with your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup a Point2Point connection with your camera`}</h2>
    <h3 {...{
      "id": "instarvision-2-for-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#instarvision-2-for-windows",
        "aria-label": "instarvision 2 for windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision 2 for Windows`}</h3>
    <p>{`Check out our `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Camera_List/#add-instar-p2p-cameras"
      }}>{`User Manual`}</a>{` for how to add your INSTAR P2P camera to our Windows Software InstarVision 2.`}</p>
    <h3 {...{
      "id": "instarvision-mobile-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#instarvision-mobile-app",
        "aria-label": "instarvision mobile app permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision Mobile App`}</h3>
    <p>{`Use the INSTAR P2P Service to connect your phone to your camera the easy way. Just search for the QR Code under Network/P2P and scan it with our smartphone app for Android, iOS, Windows Phone or Windows 8/10 (Metro App). Point-to-Point allows you to access your camera over the internet from where ever you have a LTE connection.`}</p>
    <p><strong parentName="p">{`Use your smartphone or tablet to control your camera:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Windows_Phone/P2P/"
        }}>{`Windows Phone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Metro/P2P/"
        }}>{`Windows 8/10 (Metro)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/P2P/"
        }}>{`iPhone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/P2P/"
        }}>{`iPad`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Android/InstarVision/P2P/"
        }}>{`Android`}</a></li>
    </ul>
    <p>{`Please be aware that you cannot access snapshots on your camera´s SD card over the P2P connection - but you can play recorded videos. The Point-2-Point connection is slower and does not give you access to your camera´s web user interface. But it does not rely on you forwarding a port through your internet router. And it works perfectly over cable or LTE connections that don´t provide a public IPv4 for your internet router.`}</p>
    <h3 {...{
      "id": "instarvision-2-for-windows-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#instarvision-2-for-windows-1",
        "aria-label": "instarvision 2 for windows 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision 2 for Windows`}</h3>
    <p>{`If you are using a Windows Tablet or Laptop, check out our `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Camera_List/#add-instar-p2p-cameras"
      }}>{`User Manual`}</a>{` for how to add your INSTAR IP camera to our Windows Software InstarVision 2.`}</p>
    <p>{`But - if your internet access permits - it is preferable to use the INSTAR DDNS service instead. Even forwarding a port is not so hard when you follow our DDNS Guide:`}</p>
    <Link to="/Internet_Access/The_DDNS_Service/" mdxType="Link"><EuiButton fill mdxType="EuiButton">DDNS Installation</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideo mdxType="YoutubeVideo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      